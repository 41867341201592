import React      from 'react'
import Modal from '../../utilities/modal'
import Style from './style.sass'
import Icon  from 'users/icon'

// 担当者
import Clients    from '../../clients'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class Search extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      companies: [],
      company_id: this.props.company_id,
      name: '',
      clients: [],
    }
  }

  componentDidMount() {
    
    if (this.props.company_id) {

      this.get_company(this.props.company_id);
    }
  }

  index = () => {
    return this.props.index;
  }

  get_company = (company_id) => {

    if (!company_id) return false;

    // 会社情報の取得
    Request.get(`/users/get_company/${company_id}/`)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success' && response.body.company) {

          let clients = response.body.clients;

          if (clients.length == 0) {
            clients.push({
              id: '',
              company_id: response.body.company.id,
              name: '',
              email: '',
              tel: '',
              tel2: '',
              fax: '',
              post: '',
              note: '',
              main: false,
              has_card: false,
              is_president: false,
              _destroy: false,
              editing: true,
            });
          }

          const company = response.body.company;

          this.setState({
            company_id: company.id,
            name: company.name,
            company: company,
            clients: clients,
          }, () => {
            this.props.changeCompany(company);
            this.refs.clients.updateClients();
          });

        } else {
          window.alertable({ type: 'error', message: '[初期表示]エラーにより会社情報が取得できませんでした。' });
        }
      });
  }

  /**
   *  submit処理
   *  @version 2018/06/10
   */
  _onSubmit = e => {

    e.stopPropagation();

    this.get_company(e.target.dataset.id);
    this.refs.regist_modal.close();
  }

  /**
   *  会社IDの取得
   *  @version 2018/06/10
   */
  company = () => {

    return this.state.company;
  }

  /**
   *  担当者の取得
   *  @version 2018/06/10
   */
  clients = () => {

    return this.refs.clients.clients();
  }

  onChangeName = (e) => {
    
    e.stopPropagation();

    this.setState({ name: e.target.value });
  }

  /**
   *  モーダルを表示する
   *  @version 2018/06/10
   */
  open = () => {

    if (this.state.name == '') {
      window.alertable({ type: 'warning', message: '会社名を入力してください' });
      return false;
    }

    window.startLoading();

    let url = `/users/get_companies/?name=${encodeURIComponent(this.state.name)}`;

    if (this.refs.name_search_type && this.refs.name_search_type.value != '') url += `&name_search_type=${this.refs.name_search_type.value}`;
    if (this.refs.prefecture_id && this.refs.prefecture_id.value != '') url += `&prefecture_id=${this.refs.prefecture_id.value}`;

    // 会社情報の取得
    Request.get(url)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success' && response.body.companies) {

          const companies = response.body.companies;

          this.setState({companies: companies || []}, () => {

            window.closeLoading();

            if (companies.length > 0) {
              this.refs.regist_modal.open(); 
            } else {
              window.alertable({ type: 'warning', message: '会社が見つかりませんでした。' });
            }
          });
        } else {
          this.setState({companies: []}, () => {
            window.closeLoading();
            window.alertable({ type: 'error', message: '[会社情報取得]エラーにより会社情報が取得できませんでした。' });
          });
        }
      });
  }

  /**
   *  アラートに表示するコンテンツ
   *  @version 2018/06/10
   */
  content() {

    return (
      <div className={Style.Search}>
        <div className={Style.Search__inner}>
          { this.state.companies.map((company, index) => {
            const key = 'company-'+index;
            return (
              <div {...{key}} style={{position: 'relative'}}>
                <a href={`/users/companies/${company.id}`} target='_blank' className={`${Style.Search__target} u-ml-15`}><Icon name='share' size='s' color='blue' /></a>
                <div className={Style.Search__item} data-name={company.name} data-id={company.id} onClick={this._onSubmit}>
                  <div className='u-fs-large u-fw-bold'>
                    {company.name}
                  </div>
                  {company.company_label ? <div dangerouslySetInnerHTML={{ __html: company.company_label}}></div> : null}
                  <div>
                    {company.anti_checked == 'approved' ? <span class='c-form__nda u-mr-5'>反社チェックOK</span> : null}
                    {company.compliance_checked == 'compliance_approved' ? <span class='c-form__nda u-mr-5'>コンプラチェックOK</span> : null}
                  </div>
                  <div><span className='u-fc-thinBlack'>ID:</span> {company.id}<span className='u-fc-thinBlack u-ml-30'>法人番号:</span> {company.number || '-'}</div>
                  <div><span className='u-fc-thinBlack'>都道府県:</span> {company.prefecture}</div>
                  <div><span className='u-fc-thinBlack'>住所:</span> {company.address}</div>
                  <div><span className='u-fc-thinBlack'>代表者名:</span> {company.president_name}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        <div className='c-form-label'>
          <label htmlFor='company_name'>会社名</label>
          <span className='c-form__required u-ml-10'>必須</span>
        </div>
        <div>
          <div className='u-display-flex'>
            <input type='hidden' value={this.state.company_id || ''} />
            <div style={{flex: 1, position: 'relative'}}>
              <input type='text' value={this.state.name} onChange={this.onChangeName} className='c-form-text' placeholder='会社名もしくはIDを入力してください' required={true}/>

              <div className={Style.Search__type}>
                <select className={Style.Search__select} defaultValue={''} ref='name_search_type'>
                  <option value=''>あいまい</option>
                  <option value='prefix'>前方一致</option>
                  <option value='absolute'>完全一致</option>
                </select>
              </div>

              <div className={Style.Search__prefecture}>
                <select className={Style.Search__select} defaultValue={''} ref='prefecture_id'>
                  <option value=''>全国</option>
                  <option value='1'>北海道</option>
                  <option value='2'>青森県</option>
                  <option value='3'>岩手県</option>
                  <option value='4'>宮城県</option>
                  <option value='5'>秋田県</option>
                  <option value='6'>山形県</option>
                  <option value='7'>福島県</option>
                  <option value='8'>茨城県</option>
                  <option value='9'>栃木県</option>
                  <option value='10'>群馬県</option>
                  <option value='11'>埼玉県</option>
                  <option value='12'>千葉県</option>
                  <option value='13'>東京都</option>
                  <option value='14'>神奈川県</option>
                  <option value='15'>新潟県</option>
                  <option value='16'>富山県</option>
                  <option value='17'>石川県</option>
                  <option value='18'>福井県</option>
                  <option value='19'>山梨県</option>
                  <option value='20'>長野県</option>
                  <option value='21'>岐阜県</option>
                  <option value='22'>静岡県</option>
                  <option value='23'>愛知県</option>
                  <option value='24'>三重県</option>
                  <option value='25'>滋賀県</option>
                  <option value='26'>京都府</option>
                  <option value='27'>大阪府</option>
                  <option value='28'>兵庫県</option>
                  <option value='29'>奈良県</option>
                  <option value='30'>和歌山県</option>
                  <option value='31'>鳥取県</option>
                  <option value='32'>島根県</option>
                  <option value='33'>岡山県</option>
                  <option value='34'>広島県</option>
                  <option value='35'>山口県</option>
                  <option value='36'>徳島県</option>
                  <option value='37'>香川県</option>
                  <option value='38'>愛媛県</option>
                  <option value='39'>高知県</option>
                  <option value='40'>福岡県</option>
                  <option value='41'>佐賀県</option>
                  <option value='42'>長崎県</option>
                  <option value='43'>熊本県</option>
                  <option value='44'>大分県</option>
                  <option value='45'>宮崎県</option>
                  <option value='46'>鹿児島県</option>
                  <option value='47'>沖縄県</option>
                  <option value='48'>海外</option>
                </select>
              </div>
              
              { this.state.company_id ?
                <div className={Style.Search__checked}></div>
                : null
              }
            </div>
            <div onClick={this.open} className='c-btnMain-standard u-ml-15' style={{width: '30%', lineHeight: '30px'}}>チェック</div>
          </div>
          <Modal ref='regist_modal' yield={this.content()}/>
        </div>

        { this.state.company && this.state.company.company_label ?
          <div dangerouslySetInnerHTML={{ __html: this.state.company.company_label}}></div>
          : null
        }

        { !this.state.company_id ?
          <div className='u-fs-small u-fc-thinBlack u-fw-normal'>※ 会社名もしくはIDを入力しチェックを行ってください。</div> : null
        }

        { this.state.clients.length > 0 && this.state.company_id ?
          <div className='u-mt-45'>
            <div className='c-attention u-fs-small u-mb-15'>
              <div className='c-attention__title'>
                ⚠️<span className='u-fc-red u-fw-bold'>注意</span>
              </div>
              <span className='u-fw-bold'>担当者名</span>と<span className='u-fw-bold'>メールアドレス</span>は<span className='u-fc-red u-fw-bold'>入力必須</span>です。
            </div>
            <Clients company_id={this.state.company_id} clients={this.state.clients} ref='clients' hidden_detail={true} />
          </div>
          : null
        }
      </div>
    );
  }
}
