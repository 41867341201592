import React      from 'react'
import Dayjs   from 'dayjs'
import Modal from '../utilities/modal'
import Icon       from 'users/icon'

import DatePicker    from '../date_picker'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

import { APPOINTMENT_TYPES, SALES_TYPES, PRIORITIES } from './properties'

/**
 *  アポ取得
 *  @version 2018/06/10
 */
export default class Appointment extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      appointment_at: null,
      appointment_type: 'web',
      sales_type: 'new_customer',
      priority: 'low',
    }
  }

  /**
   *  レンダリング成功時
   *  @version 2018/06/10
   */
  componentDidMount() {}

  // アポ日
  setAppointmentAt = datetime => {
    this.setState({appointment_at: datetime.datetime})
  }

  // アポ種別
  changeAppointmentType = e => {

    this.setState({
      appointment_type: e.target.value,
    })
  }

  // 面談回数
  changeSalesType = e => {

    this.setState({
      sales_type: e.target.value,
    })
  }

  // 優先度
  changePriority = e => {

    this.setState({
      priority: e.target.value,
    })
  }

  /**
   *  登録
   *  @version 2018/06/10
   */
  _onSubmit = () => {

    let appointment_at = this.state.appointment_at;
    let appointment_type = this.state.appointment_type;
    let sales_type = this.state.sales_type;
    let priority = this.state.priority;
    let body = this.refs.comment.value;

    if (!appointment_at || !appointment_type || appointment_at == '') {

      window.alertable({ type: 'warning', message: 'アポ日は入力必須です。' });
      return false;
    }

    let field = {
      'appointment[company_id]': this.props.company_id,
      'appointment[body]': body,
      'appointment[appointment_at]': appointment_at || '',
      'appointment[appointment_type]': appointment_type || 'web',
      'appointment[sales_type]': sales_type || 'new_customer',
      'appointment[priority]': priority || 'low',
      'report': this.refs.report.checked,
    };

    // 売手開拓リストIDが指定されている場合
    if (this.props.group_company_id) field['group_company_id'] = this.props.group_company_id;

    // アポ登録
    Request.post(`/users/appointments`)
      .field(field)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status != 'success') {
          window.alertable({ type: 'error', message: response.body.message });
          return;
        }

        window.alertable({ type: 'success', message: 'アポを登録しました。', close_callback: () => {

          if (this.props.group_company_id) {
            const group_company_status = document.getElementById(`status_${this.props.group_company_id}`) || (window.parent && window.parent.document.getElementById(`status_${this.props.group_company_id}`));
            if (group_company_status != null) {
              group_company_status.value = 'appointment';
            }
          }

          this.close();
        }});
      });
  }

  /**
   *  モーダルを表示する
   *  @version 2018/06/10
   */
  open = () => {

    this.refs.appointment_modal.open();
  }

  /**
   *  モーダルを閉じる
   *  @version 2018/06/10
  */
  close = () => {

    this.refs.appointment_modal.close();
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  content() {

    return (
      <div>
        <div className='u-mt-20'>
          <p className='u-fc-red u-fs-small'>すでに架電結果にて「<span className='u-fw-bold'>アポ取得</span>」を選択して登録している場合は<br />アポ情報が既に登録されているため、こちらで再度入力する必要はありません。</p>
          <div className='c-form-label u-mt-10'>
            <label>アポ日</label>
            <span className='c-form__required u-ml-10'>必須</span>
          </div>
          <DatePicker type='text' apply={this.setAppointmentAt} required={true} />

          <div className='c-form-label u-mt-10'>
            <label>アポ種別</label>
            <span className='c-form__required u-ml-10'>必須</span>
          </div>
          <div className='c-form-selectWrap'>
            <select className='c-form-select' ref='appointment_type' onChange={this.changeAppointmentType}>
              { APPOINTMENT_TYPES.map((appointment_type, index) => {
                const key = 'appointment_type-'+index;
                return (
                  <option {...{key}} value={appointment_type.value}>{appointment_type.name}</option>
                );
              })}
            </select>
          </div>

          <div className='c-form-label u-mt-10'>
            <label>初回面談/２回目以降</label>
            <span className='c-form__required u-ml-10'>必須</span>
          </div>
          <div className='c-form-selectWrap'>
            <select className='c-form-select' ref='sales_type' onChange={this.changeSalesType}>
              { SALES_TYPES.map((sales_type, index) => {
                const key = 'sales_type-'+index;
                return (
                  <option {...{key}} value={sales_type.value}>{sales_type.name}</option>
                );
              })}
            </select>
          </div>

          <div className='c-form-label u-mt-10'>
            <label>優先度</label>
            <span className='c-form__required u-ml-10'>必須</span>
          </div>

          <div className='c-attention u-mt-30 u-fs-small u-pa-5'>
            <div className='c-attention__title'>「<span className='u-fw-bold u-fc-red'>大型案件</span>」の判断は下記の<span className='u-fw-bold u-fc-red'>どれかひとつでも該当する</span>可能性があること</div>
            <p>・売上: 1,000M程～</p>
            <p>・利益: 70M程～</p>
            <p>・純資産: 500M程～</p>
          </div>

          <div className='c-attention u-mt-30 u-fs-small u-pa-5'>
            <div className='c-attention__title'>「<span className='u-fw-bold u-fc-red'>財務優良</span>」の判断は下記の<span className='u-fw-bold u-fc-red'>どれかひとつでも該当する</span>可能性があること</div>
            <p>・売上: 300M程〜～</p>
            <p>・利益: 10M程〜</p>
            <p>・純資産: 50M程〜</p>
          </div>

          <div className='c-form-selectWrap u-mt-10'>
            <select className='c-form-select' ref='priority' onChange={this.changePriority}>
              { PRIORITIES.map((priority, index) => {
                const key = 'priority-'+index;
                return (
                  <option {...{key}} value={priority.value}>{priority.name}</option>
                );
              })}
            </select>
          </div>
        </div>

        <hr />

        <div className='c-form-label u-mt-10'>
          <label>コメント</label>
        </div>
        <textarea rows='6' className='c-form-textarea' ref='comment' placeholder='コメントを入力してください' autoComplete='off' spellCheck='false' ></textarea>

        <div>
          <div className='u-mt-15'>
            <label className='c-form-checkboxLabel'>
              <span className='c-form-label'>日報に追加</span>
              <input className='c-form-checkbox u-ml-10' type='checkbox' defaultChecked={false} ref='report' />
              <i className='c-form-checkboxIcon'></i>
            </label>
          </div>

          <div className='u-mt-5 u-fs-small'>※ 日報に追加のチェックを入れるとアポ日の日報に追加されます。<br />アポ日の日報が未作成の場合は一時保存状態の日報が自動で作成されます。</div>
        </div>
        
        <div className='u-mt-20'>
          <div className='c-btnMain-primary u-display-block' onClick={this._onSubmit}>登録する</div>
        </div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        <div onClick={this.open} className='c-btnSub-standard u-display-block'>
          <Icon name='chat' size='s' color='main' className='u-cursor-pointer' />
          アポ取得
        </div>
        <Modal ref='appointment_modal' yield={this.content()}/>
      </div>
    );
  }
}
