import React         from 'react'
import SearchCompany from '../search_company'

/**
 *  会社検索
 *  @version 2018/06/10
 */
export default class SearchCompanies extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      companies: this.props.companies || [{}],
    }
  }

  /**
   *  会社追加
   *  @version 2018/06/10
   */
  addCompany = () => {

    let companies = (this.state.companies || []).slice();
    companies.push({});
    this.setState({companies: companies});
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        { this.state.companies.map((company, index) => {
          return <SearchCompany key={`company_${index}`} company_id={company.id} company_name={company.name} name={this.props.name} required={this.props.required} label={this.props.label} />;
        })}
        <div className='c-btnMain-standard u-mt-15 c-btnSmall' onClick={this.addCompany}>＋ 会社を追加する</div>
      </div>
    );
  }
}
