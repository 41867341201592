export const NO_RESULT           = 'no_result';
export const KEY_PERSON_ABSENCE  = 'key_person_absence';
export const BLOCKED             = 'blocked';
export const TEL_APPOINTMENT     = 'tel_appointment';
export const DM_APPOINTMENT      = 'dm_appointment';
export const KEY_PERSON_REJECT   = 'key_person_reject';
export const KEY_PERSON_CONTACT  = 'key_person_contact';
export const TEL_ABSENCE         = 'absence';
export const TEL_OTHER           = 'other';
export const NORMAL_EMOTION      = 'normal';
export const ANGER_EMOTION      = 'anger';

export const RESULT_TYPES = [
  {name: 'キーマン不在(外出, 出張中, 来客対応中など)', value: KEY_PERSON_ABSENCE},
  {name: '受付ブロック', value: BLOCKED},
  {name: 'アポ取得(テレアポによる取得)', value: TEL_APPOINTMENT},
  {name: 'アポ取得(DM・手紙の反響による取得)', value: DM_APPOINTMENT},
  {name: 'キーマン拒否(キーマン接触するも、M&Aに興味無し)', value: KEY_PERSON_REJECT},
  {name: 'キーマンと接触', value: KEY_PERSON_CONTACT},
  {name: '留守電', value: TEL_ABSENCE},
  {name: 'その他(誰も電話出ない、現在使われていない等、備考欄に詳細記載)', value: TEL_OTHER},
];

export const APPOINTMENT_TYPES = [
  {name: 'オンライン', value: 'web'},
  {name: '往訪', value: 'visit'},
  {name: '来訪', value: 'come'},
];

export const EMOTIONS = [
  {name: 'ふつう', value: 'normal'},
  {name: 'お怒り', value: 'anger'},
];

export const SALES_TYPES = [
  {name: '初回面談', value: 'new_customer'},
  {name: '2回目以降の面談', value: 'existing'},
];

export const PRIORITIES = [
  {name: '優先度1: 成約可能性が限りなく低いアポイント', value: 'low'},
  {name: '優先度2: 財務普通以下且つ譲渡意思低', value: 'upper_low'},
  {name: '優先度3: 財務優良且つ譲渡意思低', value: 'middle'},
  {name: '優先度4: 財務普通以下且つ譲渡意思高(反響アポは譲渡意思が高いものとする)', value: 'upper_middle'},
  {name: '優先度5: 財務優良且つ譲渡意思高(反響アポは譲渡意思が高いものとする)', value: 'high'},
  {name: '優先度6: 大型案件', value: 'upper_high'},
];
