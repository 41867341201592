import React      from 'react'
import Modal from '../utilities/modal'
import Style from './style.sass'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  クライアント検索
 *  @version 2018/06/10
 */
export default class SearchClient extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      clients: [],
      client_id: props.client_id,
      name: '',
      client_name: props.client_name || '',
    }
  }

  componentDidMount() {
    
    if (this.props.client_id) {

      this.get_client(this.props.client_id);
    }
  }

  get_client = client_id => {

    if (!client_id) return false;

    // 会社情報の取得
    Request.get(`/users/get_client/${client_id}/`)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success' && response.body.client) {

          this.setState({
            client_id: response.body.client.id,
            client_name: response.body.client.name,
            client: response.body.client,
          });

        } else {
          window.alertable({ type: 'error', message: '[初期表示]エラーにより会社情報が取得できませんでした。' });
        }
      });
  }

  /**
   *  submit処理
   *  @version 2018/06/10
   */
  _onSubmit = e => {

    e.stopPropagation();

    this.get_client(e.target.dataset.id);
    this.refs.regist_modal.close();
  }

  /**
   *  会社IDの取得
   *  @version 2018/06/10
   */
  client = () => {

    return this.state.client;
  }

  onChangeName = (e) => {
    
    e.stopPropagation();

    this.setState({ client_name: e.target.value });
  }

  /**
   *  モーダルを表示する
   *  @version 2018/06/10
   */
  open = () => {

    if (this.state.client_name == '') {
      window.alertable({ type: 'warning', message: '個人名を入力してください' });
      return false;
    }

    window.startLoading();

    let url = `/users/get_clients/?name=${encodeURIComponent(this.state.client_name)}`;

    // 個人情報の取得
    Request.get(url)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success' && response.body.clients) {

          this.setState({clients: response.body.clients || []}, () => {

            window.closeLoading();
            
            if (response.body.clients.length > 0) {
              this.refs.regist_modal.open(); 
            } else {
              window.alertable({ type: 'warning', message: '個人情報が取得できませんでした。未登録の個人は新規登録する必要があります。' });
            }
          });
        } else {
          window.closeLoading();
          this.setState({clients: []}, () => {
            window.alertable({ type: 'error', message: '[個人情報取得]エラーにより個人情報が取得できませんでした。' });
          });
        }
      });
  }

  /**
   *  アラートに表示するコンテンツ
   *  @version 2018/06/10
   */
  content() {

    return (
      <div className={Style.Search}>
        <div className={Style.Search__inner}>
          { this.state.clients.map((client, index) => {
            const key = 'client-'+index;
            return (
              <div {...{key}} className={Style.Search__item} data-name={client.name} data-id={client.id} onClick={this._onSubmit}>
                <div className='u-fs-large u-fw-bold'>{client.name}</div>
                <div>email: {client.email}</div>
                <div>住所: {client.address}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        <div className='c-form-label u-mt-10'>
          <label htmlFor='client_name'>{this.props.label}</label>
          <span className='c-form__required u-ml-10'>必須</span>
        </div>
        <div className='u-display-flex'>
          <input type='hidden' name={this.props.name} value={this.state.client_id || ''} />
          <div style={{flex: 1, position: 'relative'}}>
            <input type='text' value={this.state.client_name} onChange={this.onChangeName} className='c-form-text' placeholder='個人名を入力してください' required={true}/>
            { this.state.client_id ?
              <div className={Style.Search__checked}></div>
              : null
            }
          </div>
          <div onClick={this.open} className='c-btnMain-standard u-ml-15' style={{width: '30%', lineHeight: '30px'}}>チェック</div>
          <Modal ref='regist_modal' yield={this.content()}/>
        </div>

        { !this.state.client_id ?
          <div className='u-mt-5 u-fs-small'>※ 個人名を入力しチェックを行ってください。未登録の場合は<a className='u-fw-bold u-fc-red u-td-underline' href='/users/clients/new' target='_blank'>こちら</a>よりご登録ください。</div> : null
        }
      </div>
    );
  }
}