export const APPOINTMENT_TYPES = [
  {name: 'オンライン', value: 'web'},
  {name: '往訪', value: 'visit'},
  {name: '来訪', value: 'come'},
];

export const SALES_TYPES = [
  {name: '初回面談', value: 'new_customer'},
  {name: '2回目以降の面談', value: 'existing'},
];

export const PRIORITIES = [
  {name: '優先度1: 成約可能性が限りなく低いアポイント', value: 'low'},
  {name: '優先度2: 財務普通以下且つ譲渡意思低', value: 'upper_low'},
  {name: '優先度3: 財務優良且つ譲渡意思低', value: 'middle'},
  {name: '優先度4: 財務普通以下且つ譲渡意思高(反響アポは譲渡意思が高いものとする)', value: 'upper_middle'},
  {name: '優先度5: 財務優良且つ譲渡意思高(反響アポは譲渡意思が高いものとする)', value: 'high'},
  {name: '優先度6: 大型案件', value: 'upper_high'},
];
